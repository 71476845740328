import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tutorialModal }) => ({
  createProjectSection: {
    marginBottom: 10,
  },
  noListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  },
  iconBg: {
    ...tutorialModal.iconBg,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  noListContent: {
    fontSize: 12,
    color: '#46464F',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  pointsListWrapper: {
    maxHeight: 200,
    overflowY: 'auto',
    width: '100%',
  },
  itemCard: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 17,
    width: '100%',
  },
  selectedItemCard: {
    border: '3px solid #E5E7EB',
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  pointName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    fontSize: 14,
  },
  iconDelete: {
    cursor: 'pointer',
  },
}));
