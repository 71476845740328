import BackdropLoader from 'components/modals/BackdropLoader';
import DeleteProjectModal from 'containers/modals/DeleteProjectModal';
import RenameProjectModal from 'containers/modals/RenameProjectModal';
import ConfirmationDeleteModal from 'containers/modals/ConfirmationDeleteModal';
import ContactUsModal from 'containers/modals/ContactUsModal';
import AskSpecificPointModal from 'containers/modals/AskSpecificPointModal';
import AskDownloadStatsModal from 'containers/modals/AskDownloadStatsModal';
import InviteCollaboratorModal from 'containers/modals/InviteCollaboratorModal';
import ChooseOfferTypeModal from 'containers/modals/ChooseOfferTypeModal';
import EditOtherProfileModal from 'containers/modals/EditOtherProfileModal';
import RemoveOtherProfileModal from 'containers/modals/RemoveOtherProfileModal';
import ResetPasswordSuccessModal from 'containers/modals/ResetPasswordSuccessModal';
import GrantAccessForCollaboratorModal from 'components/modals/GrantAccessForCollaboratorModal';
import GrantAccessToProjectModal from 'containers/modals/GrantAccessToProjectModal';
import AddUserToProjectModal from 'containers/modals/AddUserToProjectModal';
import GetMapsModal from 'containers/modals/GetMapsModal';
import NotificationModal from 'containers/modals/NotificationModal';
import StartAnalysisModal from 'containers/modals/StartAnalysisModal';
import ManageGeometriesModal from 'containers/modals/ManageGeometriesModal';
import StatsModal from 'containers/modals/StatsModal';
import ResendInvitationModal from 'containers/modals/ResendInvitationModal';
import RevokeInvitationModal from 'containers/modals/RevokeInvitationModal';
import PlanningSettingsModal from 'containers/modals/PlanningSettingsModal';
import ComparisonAgainstSatellite from 'containers/modals/ComparisonAgainstSatelliteModal';
import ComparisonAgainstSatelliteResult from 'containers/modals/ComparisonAgainstSatelliteResultModal';
import ComparisonAgainstMeasurementsModal from 'containers/modals/ComparisonAgainstMeasurementsModal';
import DeletePlanningModal from 'containers/modals/DeletePlanningModal';
import UploadPlanningSuccessModal from 'containers/modals/UploadPlanningSuccessModal';
import PlanningWaitingModal from 'components/modals/PlanningWaitingModal';
import DeleteReportModal from 'containers/modals/DeleteReportModal';
import SignUpSuccessModal from 'containers/modals/SignUpSuccessModal';
import PlanningErrorLogModal from 'containers/modals/PlanningErrorLogModal';
import PlanningSettingsErrorsModal from 'containers/modals/PlanningSettingsErrorsModal/index';
import DeleteMCModalContainer from 'containers/modals/MarineContractorsModal/Delete/index';
import PercentileModalContainer from 'containers/modals/MarineContractorsModal/Percentile/index';
import CompleteConstraintModalContainer from 'containers/modals/CompleteConstraintModal';
import EmptyOperationModal from 'containers/modals/EmptyOperationModal';
import NewProjectSelectOfferModal from 'components/projects/NewProject/NewProjectSelectOfferModal';
import NewProjectSelectRegionalDatasetModal from 'components/projects/NewProject/NewProjectSelectRegionalDatasetModal';

export const CHOOSE_OFFER_TYPE_MODAL = 'chooseProjectTypeModal';
export const DELETE_PROJECT_MODAL = 'deleteProjectModal';
export const RENAME_PROJECT_MODAL = 'renameProjectModal';
export const CONFIRMATION_MODAL = 'confirmationModal';
export const CONTACT_US_MODAL = 'contactUsModal';
export const ASK_SPECIFIC_POINT_MODAL = 'askSpecificPointModal';
export const ASK_DOWNLOAD_STATS_MODAL = 'askDownloadStatsModal';
export const INVITE_COLLABORATOR_MODAL = 'inviteCollaboratorModal';
export const EDIT_OTHER_PROFILE_MODAL = 'editOtherProfileModal';
export const REMOVE_OTHER_PROFILE_MODAL = 'removeOtherProfileModal';
export const RESET_PASSWORD_SUCCESS_MODAL = 'resetPasswordSuccessModal';
export const GRANT_ACCESS_FOR_COLLABORATOR_MODAL =
  'grantAccessForCollaboratorModal';
export const GRANT_ACCESS_TO_PROJECT_MODAL = 'grantAccessToProjectModal';
export const ADD_USER_TO_PROJECT_MODAL = 'addUserToProjectModal';
export const GET_MAPS_MODAL = 'getMapsModal';
export const NOTIFICATION_MODAL = 'notificationModal';
export const START_ANALYSIS_MODAL = 'startAnalysisModal';
export const MANAGE_GEOMETRIES_MODAL = 'manageGeometries';
export const STATS_MODAL = 'statsModal';
export const EXPORTS_MODAL = 'exportsModal';
export const RESEND_INVITATION_MODAL = 'resendInvitationModal';
export const REVOKE_INVITATION_MODAL = 'revokeInvitationModal';
export const COMPARISON_AGAINST_SATELLITE = 'comparisonAgainstSatellite';
export const COMPARISON_AGAINST_SATELLITE_RESULT =
  'comparisonAgainstSatelliteResult';
export const COMPARISON_AGAINST_MEASUREMENTS_MODAL =
  'comparisonAgainstMeasurementsModal';
export const PLANNING_SETTINGS_MODAL = 'planningSettingsModal';
export const PLANNING_SETTINGS_ERRORS_MODAL = 'planningSettingsErrorsModal';
export const DELETE_PLANNING_MODAL = 'deletePlanningModal';
export const UPLOAD_PLANNING_SUCCESS_MODAL = 'uploadPlanningSuccessModal';
export const PLANNING_WAITING_MODAL = 'planningWaitingModal';
export const DELETE_REPORT_MODAL = 'deleteReportModal';
export const SIGNUP_SUCCESS_MODAL = 'signUpSuccessModal';
export const PLANNING_ERROR_LOG_MODAL = 'planningErrorLogModal';
export const BACKDROP_LOADER_MODAL = 'backdropLoaderModal';
export const DELETE_MC_MODAL = 'deleteMCModal';
export const PERCENTILE_MODAL = 'percentileModal';
export const COMPLETE_CONSTRAINT_MODAL = 'completeConstraintModal';
export const EMPTY_OPERATION_MODAL = 'emptyOperationModal';
export const NEW_PROJECT_SELECT_OFFER_MODAL = 'newProjectSelectOfferModal';
export const NEW_PROJECT_SELECT_REGIONAL_DATASET_MODAL =
  'newProjectSelectRegionalDatasetModal';

export const NEED_UPDATE_MODAL_ID = 'NEEDUPGRADEMODALID';

export const MODAL_COMPONENTS_BY_TYPES = {
  [DELETE_PROJECT_MODAL]: DeleteProjectModal,
  [RENAME_PROJECT_MODAL]: RenameProjectModal,
  [CONFIRMATION_MODAL]: ConfirmationDeleteModal,
  [CONTACT_US_MODAL]: ContactUsModal,
  [ASK_SPECIFIC_POINT_MODAL]: AskSpecificPointModal,
  [ASK_DOWNLOAD_STATS_MODAL]: AskDownloadStatsModal,
  [CHOOSE_OFFER_TYPE_MODAL]: ChooseOfferTypeModal,
  [INVITE_COLLABORATOR_MODAL]: InviteCollaboratorModal,
  [EDIT_OTHER_PROFILE_MODAL]: EditOtherProfileModal,
  [REMOVE_OTHER_PROFILE_MODAL]: RemoveOtherProfileModal,
  [RESET_PASSWORD_SUCCESS_MODAL]: ResetPasswordSuccessModal,
  [GRANT_ACCESS_FOR_COLLABORATOR_MODAL]: GrantAccessForCollaboratorModal,
  [GRANT_ACCESS_TO_PROJECT_MODAL]: GrantAccessToProjectModal,
  [ADD_USER_TO_PROJECT_MODAL]: AddUserToProjectModal,
  [GET_MAPS_MODAL]: GetMapsModal,
  [NOTIFICATION_MODAL]: NotificationModal,
  [START_ANALYSIS_MODAL]: StartAnalysisModal,
  [STATS_MODAL]: StatsModal,
  [MANAGE_GEOMETRIES_MODAL]: ManageGeometriesModal,
  [RESEND_INVITATION_MODAL]: ResendInvitationModal,
  [REVOKE_INVITATION_MODAL]: RevokeInvitationModal,
  [COMPARISON_AGAINST_SATELLITE]: ComparisonAgainstSatellite,
  [COMPARISON_AGAINST_SATELLITE_RESULT]: ComparisonAgainstSatelliteResult,
  [COMPARISON_AGAINST_MEASUREMENTS_MODAL]: ComparisonAgainstMeasurementsModal,
  [PLANNING_SETTINGS_MODAL]: PlanningSettingsModal,
  [PLANNING_SETTINGS_ERRORS_MODAL]: PlanningSettingsErrorsModal,
  [DELETE_PLANNING_MODAL]: DeletePlanningModal,
  [UPLOAD_PLANNING_SUCCESS_MODAL]: UploadPlanningSuccessModal,
  [PLANNING_WAITING_MODAL]: PlanningWaitingModal,
  [DELETE_REPORT_MODAL]: DeleteReportModal,
  [SIGNUP_SUCCESS_MODAL]: SignUpSuccessModal,
  [PLANNING_ERROR_LOG_MODAL]: PlanningErrorLogModal,
  [BACKDROP_LOADER_MODAL]: BackdropLoader,
  [DELETE_MC_MODAL]: DeleteMCModalContainer,
  [PERCENTILE_MODAL]: PercentileModalContainer,
  [COMPLETE_CONSTRAINT_MODAL]: CompleteConstraintModalContainer,
  [EMPTY_OPERATION_MODAL]: EmptyOperationModal,
  [NEW_PROJECT_SELECT_OFFER_MODAL]: NewProjectSelectOfferModal,
  [NEW_PROJECT_SELECT_REGIONAL_DATASET_MODAL]: NewProjectSelectRegionalDatasetModal,
};
