import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NewProjectWrapper from 'components/projects/NewProject/NewProjectWrapper';
import { selectOffers } from 'ducks/offers/selectors';
import { selectZonesCollection } from 'ducks/zones/selectors';
import { requestOffers } from 'ducks/offers/actions';
import { requestZonesIfNeed } from 'ducks/zones/actions';

/**
 * NewProject container
 */
const NewProjectContainer = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectOffers);
  const allZones = useSelector(selectZonesCollection);

  useEffect(() => {
    dispatch(requestOffers());
    dispatch(requestZonesIfNeed());
  }, [dispatch]);

  return <NewProjectWrapper offers={offers} zones={allZones} />;
};

export default NewProjectContainer;
