import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Leaflet from 'leaflet-providers';
import { useHistory, generatePath } from 'react-router';

import { ROUTES } from 'constants/routes';
import NewProjectMap from 'components/projects/NewProject/NewProjectMap';
import NewProjectPointsList from 'components/projects/NewProject/NewProjectPointsList';
import NewProjectOffer from 'components/projects/NewProject/NewProjectOffer';
import NewProjectDataset from 'components/projects/NewProject/NewProjectDataset';
import NewProjectName from 'components/projects/NewProject/NewProjectName';
import { selectOfferZonesById } from 'ducks/offers/selectors';
import { selectMatchingZonesIds } from 'ducks/zones/selectors';
import { requestMatchingZones } from 'ducks/zones/actions';
import { createProjectWithBulkPoints } from 'ducks/app/actions';
import { selectCurrentUser } from 'ducks/user/selectors';
import { selectBulkData } from 'ducks/points/selectors';
import { resetBulkData } from 'ducks/points/actions';

import { useStyles } from './styles';

const NO_MATCHING_ZONES_ERROR =
  'There is no matching zone in your offer for this set of points';

const NewProjectWrapper = ({ offers, zones }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const notExpiredOffers = offers.filter((offer) => !offer.expired);
  const [pointsList, setPointsList] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [newProjectName, setNewProjectName] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [errorNoMatchingZone, setErrorNoMatchingZone] = useState(false);
  const [currentMatchingZones, setCurrentMatchingZones] = useState([]);
  const [
    forceUpdateSelectedMatchingZones,
    setForceUpdateSelectedMatchingZones,
  ] = useState(false);

  const currentUser = useSelector(selectCurrentUser);
  const bulkPointsData = useSelector(selectBulkData);

  // Redirect to project when project and points are created
  useEffect(() => {
    if (bulkPointsData && bulkPointsData.status === 'success') {
      const projectPath = generatePath(ROUTES.project, {
        id: bulkPointsData.projectId,
      });
      history.push(projectPath);
      dispatch(resetBulkData());
    }
  }, [dispatch, bulkPointsData]);

  // Select default offer in available offers list
  useEffect(() => {
    if (offers && offers.length) {
      const unlimitedOffer = notExpiredOffers.find(
        (offer) => offer.isUnlimited && !offer.isTrial && !offer.isMcDashboard
      );
      const limitedOffer = notExpiredOffers.find(
        (offer) => !offer.isUnlimited && !offer.isTrial && !offer.isMcDashboard
      );
      const trialOffer = notExpiredOffers.find((offer) => offer.isTrial);
      setSelectedOffer(unlimitedOffer || limitedOffer || trialOffer);
    }
  }, [offers]);

  // Compute bounds from points list each time points list or offer is updated
  useEffect(() => {
    if (pointsList.length > 0) {
      computeBoundsFromList(pointsList);
    } else {
      // Reset matching zones if no points
      getMatchingZones(null);
      setSelectedZoneId(null);
    }
  }, [pointsList, selectedOffer]);

  const offerZones = useSelector((state) =>
    selectOfferZonesById(state, selectedOffer)
  );
  const offerZoneIds = useMemo(() => offerZones.map(({ id }) => id), [
    offerZones,
  ]);

  // Map is clickable only on zones which are not private or included in offer
  const { publicZones } = useMemo(() => {
    const offerIds = offerZones.map(({ id }) => id);
    const allPublicZones = zones.filter(
      ({ id, isPrivate }) => !isPrivate || offerIds.includes(id)
    );
    return {
      publicZones: allPublicZones,
    };
  }, [zones]);

  // Get matching zones from selector
  const matchingZonesIds = useSelector(selectMatchingZonesIds);

  // Check if selectedMatchingZoneId must be force to update if matchingZoneIds has changed
  useEffect(() => {
    // Init currentMatchingZones with first value from matchingZonesIds
    if (currentMatchingZones.length === 0) {
      setCurrentMatchingZones(matchingZonesIds);
    } else {
      // Update currentMatchingZones only if matchingZonesIds has changed
      const matchingZonesChange = currentMatchingZones !== matchingZonesIds;
      setCurrentMatchingZones(
        matchingZonesChange ? matchingZonesIds : currentMatchingZones
      );
      setForceUpdateSelectedMatchingZones(matchingZonesChange);
    }
  }, [
    matchingZonesIds,
    setCurrentMatchingZones,
    setForceUpdateSelectedMatchingZones,
  ]);

  // Select first matching zone from endpoint by default
  const selectedMatchingZoneId = useMemo(() => {
    const hasNoMatchingZones = matchingZonesIds === NO_MATCHING_ZONES_ERROR;
    setErrorNoMatchingZone(hasNoMatchingZones);
    // If set and matchingZones list has not been updated, rere use selectedZoneId as selectedMatchingZoneId
    if (selectedZoneId && !forceUpdateSelectedMatchingZones) {
      return selectedZoneId;
    }
    const defaultMatchingZone =
      !hasNoMatchingZones && matchingZonesIds.length > 0
        ? matchingZonesIds[0]
        : null;
    const preselectedMatchingZone = hasNoMatchingZones
      ? null
      : defaultMatchingZone;
    const id = pointsList.length === 0 ? null : preselectedMatchingZone;
    setSelectedZoneId(id);
    return id;
  }, [
    selectedOffer,
    selectedZoneId,
    matchingZonesIds,
    pointsList,
    forceUpdateSelectedMatchingZones,
  ]);

  const handleAddPointToList = useCallback(
    (data) => {
      setPointsList((prevList) => [...prevList, data]);
    },
    [setPointsList]
  );

  // Save points list
  const handlePointsList = useCallback((list) => {
    setPointsList(list);
  });

  const computeBoundsFromList = useCallback((list) => {
    const leafletBounds = Leaflet.latLngBounds(list);
    const bounds = {
      northEast: {
        lat: leafletBounds._northEast.lat,
        lon: leafletBounds._northEast.lng,
      },
      southWest: {
        lat: leafletBounds._southWest.lat,
        lon: leafletBounds._southWest.lng,
      },
    };
    getMatchingZones(bounds);
  });

  const getMatchingZones = useCallback((bounds) => {
    dispatch(requestMatchingZones(bounds, selectedOffer?.id));
  });

  const handleProjectName = useCallback(
    (name) => {
      setNewProjectName(name);
    },
    [setNewProjectName]
  );

  const handleProjectCreation = useCallback(() => {
    const projectPayload = {
      collaborators: [currentUser.id],
      offerInstance: selectedOffer.id,
      zone: selectedZoneId,
      name: newProjectName,
    };
    dispatch(
      createProjectWithBulkPoints({
        project: projectPayload,
        points: [...pointsList],
      })
    );
  }, [
    dispatch,
    pointsList,
    currentUser.id,
    selectedOffer,
    selectedZoneId,
    newProjectName,
  ]);

  const handleSelectedMarker = useCallback((selectedMarker) => {
    setSelectedPoint(selectedMarker);
  });

  const handleUpdateSelectedMatchingZone = useCallback((zone) => {
    setSelectedZoneId(zone.id);
    setForceUpdateSelectedMatchingZones(false); // reset
  });

  const handleSaveOffer = useCallback((savedOffer) => {
    setSelectedOffer(savedOffer);
  });

  return (
    <Grid container className={classes.createProjectContainer} wrap="nowrap">
      <Grid item className={classes.createProjectMap}>
        {selectedOffer && (
          <NewProjectMap
            zones={publicZones}
            offerZoneIds={offerZoneIds}
            handleAddPointToList={handleAddPointToList}
            handleSelectedMarker={handleSelectedMarker}
            pointsList={pointsList}
            onSelect={() => {}}
          ></NewProjectMap>
        )}
      </Grid>
      <Grid item>
        <div className={classes.createProjectSideBar}>
          <NewProjectPointsList
            pointsList={pointsList}
            selectedPoint={selectedPoint}
            handleUpdatePointsList={handlePointsList}
          />
          <NewProjectOffer
            selectedOffer={selectedOffer}
            notExpiredOffers={notExpiredOffers}
            handleSaveOffer={handleSaveOffer}
          />
          <NewProjectDataset
            errorNoMatchingZone={errorNoMatchingZone}
            selectedMatchingZoneId={selectedMatchingZoneId}
            allMatchingZonesIds={errorNoMatchingZone ? [] : matchingZonesIds}
            handleUpdateSelectedMatchingZone={handleUpdateSelectedMatchingZone}
          />
          <NewProjectName handleProjectName={handleProjectName} />
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.submitButton}
              onClick={handleProjectCreation}
              disabled={
                pointsList.length === 0 ||
                !selectedOffer ||
                !newProjectName ||
                !selectedZoneId
              }
            >
              Create Project
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

NewProjectWrapper.propTypes = {
  offers: PropTypes.array.isRequired,
};

export default React.memo(NewProjectWrapper);
