import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  createProjectSection: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 10,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 5,
    border: '1px solid #E5E7EB',
    borderRadius: 8,
    padding: 17,
    width: '100%',
  },
  selectedItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 5,
    fontSize: 14,
    marginBottom: 5,
    border: `2px solid ${palette.sinay.primary['50']}`,
    borderRadius: 8,
    padding: 17,
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  cursor: {
    cursor: 'pointer',
  },
}));
