import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { normalizeArray } from 'helpers/data';

import {
  REQUEST_ZONES,
  REQUEST_ZONES_SUCCESS,
  REQUEST_ZONES_ERROR,
  REQUEST_ZONE,
  REQUEST_ZONE_SUCCESS,
  REQUEST_ZONE_ERROR,
  REQUEST_MATCHING_ZONES_SUCCESS,
  REQUEST_MATCHING_ZONES_ERROR,
  REQUEST_MATCHING_ZONE_DATA,
  REQUEST_MATCHING_ZONE_DATA_SUCCESS,
  REQUEST_MATCHING_ZONE_DATA_ERROR,
  RESET_MATCHING_ZONE_DATA,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
  matchingZonesIds: [],
  matchingZonesData: [],
  matchingZonesStatus: STATUS_NOT_REQUESTED,
};
const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setZones = (state, { zones }) => ({
  ...state,
  ...normalizeArray(zones),
  status: STATUS_SUCCESS,
});

const setZone = (state, { zone }) => {
  const { [zone.id]: deleted, ...data } = state.data;
  data[zone.id] = zone;
  return {
    ...state,
    data,
    ids: state.ids.includes(zone.id) ? state.ids : [...state.ids, zone.id],
    status: STATUS_SUCCESS,
  };
};

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const setMatchingZones = (state, { zone }) => ({
  ...state,
  matchingZonesIds: zone,
});

const setMatchingZonesError = (state, { error }) => ({
  ...state,
  error,
  matchingZonesStatus: STATUS_ERROR,
});

const setMatchingZonesLoadingStatus = (state) => ({
  ...state,
  matchingZonesStatus: STATUS_LOADING,
});

const setMatchingZoneData = (state, { zone }) => {
  const list = [...state.matchingZonesData];
  const isDuplicate = list.find((item) => item.id === zone.id);
  const updatedList = isDuplicate ? list : [...state.matchingZonesData, zone];
  return {
    ...state,
    matchingZonesData: updatedList,
    matchingZonesStatus: STATUS_SUCCESS,
  };
};

const setMatchingZoneError = (state, { error }) => ({
  ...state,
  error,
  matchingZonesStatus: STATUS_ERROR,
});

const resetMatchingZoneData = (state) => ({
  ...state,
  matchingZonesData: [],
  matchingZonesStatus: STATUS_SUCCESS,
});

export const zonesReducer = createReducer(initialState, {
  [REQUEST_ZONES]: setLoadingStatus,
  [REQUEST_ZONE]: setLoadingStatus,
  [REQUEST_ZONES_SUCCESS]: setZones,
  [REQUEST_ZONE_SUCCESS]: setZone,
  [REQUEST_ZONES_ERROR]: setError,
  [REQUEST_ZONE_ERROR]: setError,
  [REQUEST_MATCHING_ZONES_SUCCESS]: setMatchingZones,
  [REQUEST_MATCHING_ZONES_ERROR]: setMatchingZonesError,
  [REQUEST_MATCHING_ZONE_DATA]: setMatchingZonesLoadingStatus,
  [REQUEST_MATCHING_ZONE_DATA_SUCCESS]: setMatchingZoneData,
  [REQUEST_MATCHING_ZONE_DATA_ERROR]: setMatchingZoneError,
  [RESET_MATCHING_ZONE_DATA]: resetMatchingZoneData,
});
