import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ navbarHeight, buttons }) => ({
  createProjectContainer: {
    position: 'fixed',
    top: navbarHeight,
    zIndex: 1101,
    right: 0,
    left: 0,
    bottom: 0,
  },
  createProjectSideBar: {
    padding: 20,
    position: 'relative',
    width: '400px',
    zIndex: 1000,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    overflowY: 'auto',
  },
  createProjectMap: {
    width: 'calc(100% - 400px)',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  submitButton: {
    ...buttons.fill.primary,
  },
}));
