import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { MapTrifold, MapPin, Trash } from 'phosphor-react';
import classNames from 'classnames';

import { useStyles } from './styles';

const PointItemRow = ({ point, selectedPoint, deletePoint }) => {
  const classes = useStyles();
  const handleDeletePoint = useCallback(() => {
    deletePoint(point.name);
  });
  return (
    <div
      className={classNames(classes.itemCard, {
        [classes.selectedItemCard]:
          selectedPoint && selectedPoint.name === point.name,
      })}
    >
      <div className={classes.itemRowContainer}>
        <div className={classes.pointName}>
          <MapPin size={20} />
          <span>
            {point.name} ({point.lat.toFixed(4)}, {point.lng.toFixed(4)})
          </span>
        </div>
        <Trash
          size={20}
          onClick={handleDeletePoint}
          id={point.name}
          className={classes.iconDelete}
        />
      </div>
    </div>
  );
};

const NewProjectPointsList = ({
  pointsList,
  selectedPoint,
  handleUpdatePointsList,
}) => {
  const classes = useStyles();

  const handleDeletePoint = useCallback((nameToDelete) => {
    const updatedList = pointsList.filter(({ name }) => name !== nameToDelete);
    handleUpdatePointsList(updatedList);
  });

  return (
    <div className={classes.createProjectSection}>
      {pointsList.length === 0 && (
        <div className={classes.noListContainer}>
          <MapTrifold className={classes.iconBg} />
          <div className={classes.subtitle}>Select point location</div>
          <div className={classes.noListContent}>
            Click on the map to place your point.
          </div>
        </div>
      )}
      {pointsList.length > 0 && (
        <div className={classes.listContainer}>
          <div className={classes.subtitle}>Point Selection</div>
          <div className={classes.pointsListWrapper}>
            {pointsList &&
              pointsList.map((point) => (
                <PointItemRow
                  key={point.name}
                  point={point}
                  selectedPoint={selectedPoint}
                  deletePoint={handleDeletePoint}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

NewProjectPointsList.propTypes = {
  pointsList: PropTypes.array.isRequired,
  selectedPoint: PropTypes.object,
  handleUpdatePointsList: PropTypes.func,
};

export default React.memo(NewProjectPointsList);
