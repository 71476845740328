import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  container: {
    width: 800,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 20,
  },
  listWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  offerWrapper: {
    border: `1px solid ${palette.sinay.neutral[80]}`,
    padding: 10,
    width: '100%',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  selectedOfferWrapper: {
    border: `2px solid ${palette.sinay.primary[40]}`,
  },
  name: {
    fontWeight: 600,
    marginBottom: 5,
  },
  label: {
    fontWeight: 600,
  },
  description: {
    fontSize: 12,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cancelButton: {
    ...buttons.outlined.primary,
  },
  saveButton: {
    ...buttons.fill.primary,
  },
}));
