import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio, Button } from '@material-ui/core';
import { Clock } from 'phosphor-react';

import { useModal } from 'hooks/useModal';
import BaseModal from 'components/common/BaseModal';
import DatasetIcon from 'components/common/DatasetIcon';
import { AVAILABLE_GRIDS } from 'constants/map';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';

import { useStyles } from './styles';

const DatasetAllDetails = ({ dataset }) => {
  const classes = useStyles();

  const [availableDatasetDimensions, setAvailableDatasetDimensions] = useState(
    []
  );
  const [selectedDatasetDimension, setSelectedDatasetDimension] = useState(
    null
  );

  // Init selection with first value by default
  useEffect(() => {
    setSelectedDatasetDimension(availableDatasetDimensions[0]);
  }, [dataset, availableDatasetDimensions]);

  // Check which dataset dimension are available for selected dataset
  useEffect(() => {
    setAvailableDatasetDimensions([]); // reset
    AVAILABLE_GRIDS.forEach((dimension) => {
      if (dataset[dimension.id] && dataset[dimension.id].length > 0) {
        setAvailableDatasetDimensions((prevState) => [...prevState, dimension]);
      }
    });
  }, [dataset, setAvailableDatasetDimensions]);

  const handleChangeDatasetDimension = useCallback((newId) => {
    const newDatasetDimension = AVAILABLE_GRIDS.find(
      (item) => item.id === newId
    );
    setSelectedDatasetDimension(newDatasetDimension);
  });

  return (
    <div className={classes.detailsContainer}>
      <div className={classes.subtitleContainer}>
        <div className={classes.itemName}>{dataset.name}</div>
        <div className={classes.dimensionSelectorWrapper}>
          <div className={classes.dimensionLabel}>
            {selectedDatasetDimension?.name}
          </div>
          <div className={classes.iconsWrapper}>
            {availableDatasetDimensions.map((availableDimension) => (
              <DatasetIcon
                key={availableDimension.id}
                id={availableDimension.id}
                customClass={
                  availableDimension.id === selectedDatasetDimension?.id
                    ? 'selectedDimensionIcon'
                    : 'dimensionIcon'
                }
                handleClick={handleChangeDatasetDimension}
              />
            ))}
          </div>
        </div>
      </div>

      <div className={classes.detailsWrapper}>
        {selectedDatasetDimension?.id && dataset[selectedDatasetDimension?.id] && (
          <>
            <div className={classes.dimensionDetailsContainer}>
              {/** Spatial Resolution */}
              {dataset[selectedDatasetDimension.id][0] &&
                dataset[selectedDatasetDimension.id][0].spatialResolution && (
                  <div className={classes.dimensionDetailsItemContainer}>
                    <DatasetIcon id="resolution" customSize={20} />
                    <div className={classes.dimensionDetailsContent}>
                      <div className={classes.dimensionDetailsItemLabel}>
                        Spatial Resolution
                      </div>
                      <div>
                        {
                          dataset[selectedDatasetDimension.id][0]
                            .spatialResolution
                        }{' '}
                        {
                          dataset[selectedDatasetDimension.id][0]
                            ?.spatialResolutionUnits
                        }
                      </div>
                    </div>
                  </div>
                )}
              {/** Temporal Resolution */}
              {dataset[selectedDatasetDimension.id][0] &&
                dataset[selectedDatasetDimension.id][0].timeStep && (
                  <div className={classes.dimensionDetailsItemContainer}>
                    <Clock size={20} color="#3643BA" />
                    <div className={classes.dimensionDetailsContent}>
                      <div className={classes.dimensionDetailsItemLabel}>
                        Temporal Resolution
                      </div>
                      <div>
                        {dataset[selectedDatasetDimension.id][0].timeStep}{' '}
                        {dataset[selectedDatasetDimension.id][0].timeStepUnits}
                      </div>
                    </div>
                  </div>
                )}
              {/** Period covered */}
              {dataset[selectedDatasetDimension.id][0] &&
                dataset[selectedDatasetDimension.id][0].dateBegin && (
                  <div className={classes.dimensionDetailsItemContainer}>
                    <DatasetIcon id="calendar" customSize={20} />
                    <div className={classes.dimensionDetailsContent}>
                      <div className={classes.dimensionDetailsItemLabel}>
                        Period Covered
                      </div>
                      <div>
                        {formatDate(
                          dataset[selectedDatasetDimension.id][0].dateBegin,
                          DATE_FORMATS.shortMonthFormat
                        )}
                        {' - '}
                        {formatDate(
                          dataset[selectedDatasetDimension.id][0].dateEnd,
                          DATE_FORMATS.shortMonthFormat
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className={classes.dimensionDetails2ndRowContainer}>
              {/** origin */}
              {dataset[selectedDatasetDimension.id][0] &&
                dataset[selectedDatasetDimension.id][0].origin && (
                  <div className={classes.dimensionDetailsItemContainer}>
                    <div className={classes.dimensionDetailsContent}>
                      <div className={classes.dimensionDetailsItemLabel}>
                        Origin
                      </div>
                      <div>
                        {dataset[selectedDatasetDimension.id][0].origin}
                      </div>
                    </div>
                  </div>
                )}
              {/** notes */}
              {dataset[selectedDatasetDimension.id][0] &&
                dataset[selectedDatasetDimension.id][0].notes && (
                  <div className={classes.dimensionDetailsItemNotesContainer}>
                    <div className={classes.dimensionDetailsContent}>
                      <div className={classes.dimensionDetailsItemLabel}>
                        Notes
                      </div>
                      <div>{dataset[selectedDatasetDimension.id][0].notes}</div>
                    </div>
                  </div>
                )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const RegionalDatasetCard = ({
  dataset,
  selectedDataset,
  handleChangeDataset,
}) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(dataset?.id === selectedDataset?.id);
  }, [selectedDataset, dataset]);

  const changeDataset = useCallback(() => {
    handleChangeDataset(dataset);
  });

  return (
    <div onClick={changeDataset} className={classes.itemContainer}>
      <Radio checked={isSelected} color="primary" />
      <div
        className={classNames(classes.itemWrapper, {
          [classes.selectedItemWrapper]: isSelected,
        })}
      >
        <DatasetAllDetails dataset={dataset} />
      </div>
    </div>
  );
};

const NewProjectSelectRegionalDatasetModal = ({
  defaultRegionalDataset,
  regionalMatchingZones,
  id,
  handleUpdateSelectedRegionalDataset,
}) => {
  const { onClose } = useModal(id);
  const classes = useStyles();
  const [updatedRegionalDataset, setUpdatedRegionalDataset] = useState(
    defaultRegionalDataset
  );

  const handleChangeRegionalDataset = useCallback((dataset) => {
    setUpdatedRegionalDataset(dataset);
  });

  const handleCancel = useCallback(() => {
    onClose();
  });
  const handleSaveRegionalDataset = useCallback(() => {
    handleUpdateSelectedRegionalDataset(updatedRegionalDataset);
    onClose();
  });

  const controls = (
    <div className={classes.buttonsContainer}>
      <Button onClick={handleCancel} className={classes.cancelButton}>
        Cancel
      </Button>
      <Button
        onClick={handleSaveRegionalDataset}
        className={classes.saveButton}
      >
        Save
      </Button>
    </div>
  );

  return (
    <BaseModal
      title="Change Regional Dataset"
      onClose={onClose}
      controls={controls}
      modalClass={classes.container}
    >
      <div>
        <div className={classes.subtitle}>
          List of regional dataset available for your list of points :
        </div>

        <div className={classes.listWrapper}>
          {regionalMatchingZones.map((dataset) => (
            <RegionalDatasetCard
              key={dataset.id}
              dataset={dataset}
              selectedDataset={updatedRegionalDataset}
              handleChangeDataset={handleChangeRegionalDataset}
            />
          ))}
        </div>
      </div>
    </BaseModal>
  );
};

NewProjectSelectRegionalDatasetModal.propTypes = {
  defaultRegionalDataset: PropTypes.object,
  regionalMatchingZones: PropTypes.array,
  id: PropTypes.string.isRequired,
  handleUpdateSelectedRegionalDataset: PropTypes.func,
};

export default React.memo(NewProjectSelectRegionalDatasetModal);
