import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MapPolygon } from 'components/leaflet';
import { sortZoneByArea } from 'helpers/map';

/**
 * Render zones for map
 * @param { array } zones - available zones
 * @param { array } offerZoneIds - zone ids available for selected offer
 * @param { React.ReactNode } children
 * @param { Function } add
 * @param { Function } remove
 * @param { Function } onClick
 * @param { Object } props
 */
const NewProjectZonesLayers = ({
  zones,
  offerZoneIds,
  add,
  remove,
  onClick,
  children,
  ...props
}) => {
  const sortedZones = useMemo(() => sortZoneByArea(zones, offerZoneIds), [
    zones,
    offerZoneIds,
  ]);

  return sortedZones.map(({ id, name, geom, isWorld, isDemo }) => {
    const inOffer = offerZoneIds.includes(id);
    const fillOpacity = isWorld ? 0 : 0.1;

    return (
      <MapPolygon
        {...props}
        onClick={onClick}
        key={id}
        add={add}
        remove={remove}
        geometry={geom}
        fillOpacity={fillOpacity}
        allowOpenPopupOnSelect
        customProps={{ id, name, inOffer, isDemo }}
        hideStroke
      >
        {children}
      </MapPolygon>
    );
  });
};

NewProjectZonesLayers.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      geom: PropTypes.string,
      isWorld: PropTypes.bool,
      isDemo: PropTypes.bool,
    })
  ).isRequired,
  offerZoneIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  add: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  remove: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default memo(NewProjectZonesLayers);
